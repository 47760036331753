.header {
  background: none;
  color: grey;
  min-width: 100%;
  display: flex;
  z-index: 999;
  height: 80px;
  align-items: center; 
  justify-content: space-between; 
  padding: 0;
  transition: 0.3s ease;
  position: fixed;
}

.header-link {
  text-decoration: none;
  color: white;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.scrolled-text {
  color: #333;
  transition: color 0.3s ease;
}

.header-link:hover {
  color: #888888;
}
  
.nav {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  letter-spacing: 2px;
}

.a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.a:hover {
  background-color: grey;
  color: white;
}

.a.active{
  background-color: grey;
  color: white;
}

.scrolled-a {
  text-decoration: none;
  color: rgb(100, 100, 100);
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.scrolled-a:hover{
  background-color: rgb(100, 100, 100);
  color: white;
}

.header-button {
  border: 2px solid white;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
}

.header-button-scrolled {
  border: 2px solid black;
  color: black;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
}

.header-button-scrolled:hover {
  border: 2px solid black !important;
  background-color: black !important;
  color: white !important;
} 

.header-button:hover {
  border: 2px solid white;
  background-color: white;
  color: black;
}

.scrolled {
  background-color: white !important;
  transition: background-color 0.3s ease;
  color: white;
  min-width: 100%;
  height: 60px;
  position: fixed;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
} 

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  display: none;
}

.scrolled-mobile-menu-button {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .mobile-menu-button {
    display: block;
    margin-right: 2.5%;
    background: none;
    color: white;
    font-size: 24px;
    box-shadow: none;
    border: none;
  }

  .scrolled-mobile-menu-button {
    display: block;
    margin-right: 2.5%;
    background: none;
    color: black;
    font-size: 24px;
    box-shadow: none;
    border: none;
  }


  .mobile-menu.open {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: white;
    border: none;
    width: 50vw;
    text-align: center;
    margin-top: 80%;
    height: 100vh;
    justify-content: center;
    align-content: center;
    overflow: visible;
    transform: translateX(0%);
    transition: all 0.3s ease;
  }

  .scrolled-a {
    color: black;
  }

  .scrolled-a:hover {
    background-color: grey;
  }


}

