.chrono-icons {
    size: 24px;
}

.using-icon img {
    max-height: 400% !important;
    max-width: 400% !important;
    border: 1px solid black;
    border-radius: 50%;
}

.timeline-item-title.rc-title.active {
    background: none !important;
    color: #EB9F42 !important;
}

.timeline-item-title.rc-title {
    color: #EB9F42 !important;
}


@media (max-width: 768px) {
    .timeline-item-title.rc-title {
        padding: 0;
        min-width: 100px !important;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .hIlYuq {
        margin-left: 20px;
        margin-right: 20px;
    }

    .jCGVMh.timeline-card-content.rc-card {
        margin-left: 20px;
    }
}
